<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <vxe-button @click="addTable()">添加职位级别</vxe-button>
        <vxe-button @click="deleteTable()">清空</vxe-button>
        <!-- <vxe-button @click="download_modal()">下载模板</vxe-button>
        <el-button
          slot="buttons"
          size="mini"
          icon="el-icon-upload"
          style="position: relative; margin-left: 12px"
          >导入
          <input
            type="file"
            id="file"
            ref="files"
            style="
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            "
            @change="upload"
          />
        </el-button> -->
      </template>
    </vxe-toolbar>
    <vxe-table
      show-overflow=""
      ref="fineTable"
      :data="subjectList"
      height="300px"
      :edit-config="{ trigger: 'dblclick', mode: 'row' }"
    >
      <vxe-table-column
        min-width="100"
        field="positionLevelCode"
        title="职位级别编码"
      ></vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="positionLevelName"
        title="职位级别名称"
      ></vxe-table-column>
      <vxe-column field="taskIndicator" title="拜访任务数量指标" :edit-render="{}">
        <template #edit="{ row }">
          <vxe-input v-model="row.taskIndicator" type="integer"></vxe-input>
        </template>
      </vxe-column>
      <!-- <vxe-column
        field="terminalTaskIndicator"
        title="新增门店任务指标"
        :edit-render="{}"
      >
        <template #edit="{ row }">
          <vxe-input
            v-model="row.terminalTaskIndicator"
            type="integer"
          ></vxe-input>
        </template>
      </vxe-column>
      <vxe-column
        field="visitNumberTaskIndicator"
        title="拜访任务数量指标"
        :edit-render="{}"
      >
        <template #edit="{ row }">
          <vxe-input
            v-model="row.visitNumberTaskIndicator"
            type="integer"
          ></vxe-input>
        </template>
      </vxe-column> -->
      <vxe-column title="操作" width="80" v-if="!disabled">
        <template #default="{ row }">
          <template>
            <span @click="deletRow(row)" style="color: red; cursor: pointer"
              >删除</span
            >
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';
import request from '@/found/utils/request';

export default {
  name: 'BudgetSubject',
  components: {
    SelectConfig,
  },
  props: {
    saveRef: Function,
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value() {
      this.subjectList = this.value;
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
    };
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  methods: {
    // 上传文件
    async upload() {
      const loading = this.$loading({
        lock: true,
        text: '文件上传中...',
        spinner: 'el-icon-loading',
      });
      const fileObj = document.getElementById('file').files; // js 获取文件对象
      const form = new FormData();
      form.append('file', this.file);
      if (fileObj.length !== 0) {
        const i = fileObj.length;
        this.counts = i;
        for (let j = 0; j < i; j += 1) {
          (function (k) {
            form.append('file', fileObj[k]); // 文件对象
          }(j));
        }
      }
      try {
        const result = await request.post(
          '/sfa/sfaAttendanceTaskController/analysisFixedTaskUserExcel',
          form,
        );
        if (result.success) {
          this.$message.success('导入成功');
          loading.close();
          const resData = result.result;
          const tempArr = [];
          for (const item of resData) {
            tempArr.push({
              // userName: item.userName,
              // userCode: item.userCode,
              // terminalTaskIndicator: item.terminalTaskIndicator,
              // visitNumberTaskIndicator: item.visitNumberTaskIndicator,
              // userOrgCode: item.orgCode,
              // userOrgName: item.orgName,
              positionLevelName: item.positionLevelName,
              positionLevelCode: item.positionLevelCode,
              taskIndicator: 0,
              // terminalTaskIndicator: item.terminalTaskIndicator,
              // visitNumberTaskIndicator: item.visitNumberTaskIndicator,
            });
          }
          const { fineTable } = this.$refs;
          const { rows } = await fineTable.insertAt(tempArr, undefined);
          this.subjectList = [...rows, ...this.subjectList];
          this.$emit('input', this.subjectList);
        }
      } catch (error) {
        loading.close();
      }
    },
    // 删除行
    deletRow(row) {
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove(row);
        this.subjectList.forEach((item, index) => {
          if (item.positionLevelCode === row.positionLevelCode) {
            this.subjectList.splice(index, 1);
          }
        });
        this.$emit('change', this.subjectList);
        this.$emit('deleteAssociate', row); // 删除活动明细中的关联信息
        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 清空列表
    deleteTable() {
      this.$refs.fineTable.remove();
      this.value.data = [];
      this.subjectList = [];
      this.$emit('clear', []);
      this.$emit('input', []);
    },
    // 下载模板
    download_modal() {
      console.log(123);
      const a = document.createElement('a');
      a.href = '/excel/excelImportController/demoFile?configCode=attendance_task_report';
      // a.href = `/excel/excelImportController/demoFile?configCode=${this.propsObj.configCode}&token=${Storage.l.get('TokenKey')}`;
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
    },
    // 新增预算信息
    addTable() {
      const params = {
        functionCode: 'position_level_list',
        data: [],
        idKey: 'postionLevelCode',
      };
      this.$refs.selectConfig.openSelectModal(params);
    },

    onGetSelect(val) {
      if (this.subjectList.length === 0) {
        for (const item of val) {
          this.subjectList.push({
            positionLevelName: item.positionLevelName,
            positionLevelCode: item.positionLevelCode,
            taskIndicator: 0,
            // terminalTaskIndicator: 0,
            // visitNumberTaskIndicator: 0,
          });
        }
      } else {
        val.forEach((item) => {
          let flag = false;
          this.subjectList.forEach((sub) => {
            if (item.positionLevelCode === sub.positionLevelCode) {
              flag = true;
            }
          });
          if (!flag) {
            this.subjectList.push({
              positionLevelName: item.positionLevelName,
              positionLevelCode: item.positionLevelCode,
              taskIndicator: 0,
              // terminalTaskIndicator: 0,
              // visitNumberTaskIndicator: 0,
            });
          }
        });
      }
      this.$emit('input', this.subjectList);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
</style>
