var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "vxe-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.addTable()
                            },
                          },
                        },
                        [_vm._v("添加职位级别")]
                      ),
                      _c(
                        "vxe-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.deleteTable()
                            },
                          },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4224431693
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "fineTable",
          attrs: {
            "show-overflow": "",
            data: _vm.subjectList,
            height: "300px",
            "edit-config": { trigger: "dblclick", mode: "row" },
          },
        },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "positionLevelCode",
              title: "职位级别编码",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "positionLevelName",
              title: "职位级别名称",
            },
          }),
          _c("vxe-column", {
            attrs: {
              field: "taskIndicator",
              title: "拜访任务数量指标",
              "edit-render": {},
            },
            scopedSlots: _vm._u([
              {
                key: "edit",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("vxe-input", {
                      attrs: { type: "integer" },
                      model: {
                        value: row.taskIndicator,
                        callback: function ($$v) {
                          _vm.$set(row, "taskIndicator", $$v)
                        },
                        expression: "row.taskIndicator",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          !_vm.disabled
            ? _c("vxe-column", {
                attrs: { title: "操作", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deletRow(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  239364282
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }