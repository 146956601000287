import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import PositionLevel from './add_position_level.vue';
import AddPerson from './add_person.vue';

formCreate.component('PositionLevel', PositionLevel);
formCreate.component('AddPerson', AddPerson);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'attendance_task_form',
    };
  },

  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'effectDate') { // 活动时间
        v.validate = {
          message: '生效日期不能为空',
          required: true,
          trigger: 'change',
        };
        v.props = {
          ...v.props,
          type: 'datetimerange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
        };
      }
      if (v.field === 'positionReqVos') { // 活动时间
        v.value = [];
      }
      if (v.field === 'userRespVos') {
        v.value = [];
      }
      return item;
    },

    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/sfa/sfaAttendanceTaskController/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            const { result } = res;
            result.effectDate = [result.effectStartTime, result.effectEndTime];
            result.positionReqVos = result.positionRespVos ? result.positionRespVos : [];
            result.userReqVos = result.userRespVos ? result.userRespVos : [];
            this.setValue(result);
          }
        });
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/sfa/sfaAttendanceTaskController/save';
        // 有id  就是编辑
        if (this.formConfig.row && this.formConfig.row.id) {
          url = '/sfa/sfaAttendanceTaskController/update';
          formData.id = this.formConfig.row.id;
        }
        if (formData.effectDate && formData.effectDate.length > 0) {
          const [start, end] = formData.effectDate;
          formData.effectStartTime = start;
          formData.effectEndTime = end;
          delete formData.effectDate;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
